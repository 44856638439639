import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import ItemList from "./components/ItemList";
import CategoryList from "./components/CategoryList";
import RaceList from "./components/RaceList";
import AddRace from "./components/AddRace";
import LandingPage from "./components/LandingPage";
import Login from "./components/Login";

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <div>
                    <Switch>
                        <Route exact path="/" component={LandingPage}/>
                        <Route path="/login" component={Login}/>
                        <Route path="/category/:id/update_race" component={AddRace}/>
                        <Route path="/add-race" component={AddRace}/>
                        <Route path="/race-list" component={RaceList}/>
                        <Route path="/category/:id" component={CategoryList}/>
                        <Route path="/rider-list/:id" component={ItemList}/>
                    </Switch>
                </div>
            </BrowserRouter>
        );
    }
}

export default App;
