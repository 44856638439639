import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { Footer } from "../items/Footer";
import Constants from "../util/Constants";
import BackButton from '../items/BackButton';

class MainLayout extends Component {

    state = {
        redirect: false,
    };


    handleOnSignOutButtonClick = e => {
        e.preventDefault();
        sessionStorage.setItem('isLoggedIn', '2');
        this.setState({ redirect: true });
    };

    render() {
        return (
            <div>
                <nav className="navbar navbar-light bg-light card-shadow sticky-top">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center w-100">
                            <div>
                                {this.props.backButtonLink && (
                                    <>
                                        <BackButton link={this.props.backButtonLink} />
                                    </>
                                )}
                            </div>
                            <img src={Constants.LOGO_URL} width="Auto" height="50" className="d-inline-block align-top"
                                alt="Doltcini Logo" />
                            <div>
                                {this.props.showLogoutButton &&
                                    <button className="btn btn-danger" onClick={this.handleOnSignOutButtonClick}>Изход</button>}
                            </div>
                        </div>
                    </div>
                </nav>

                <div className="container mt-auto mb-5 pb-5 pt-5">
                    {this.props.children}
                    {this.state.redirect ? <Redirect to="/" />
                        : ""}
                </div>

                <Footer />

            </div>
        );
    }

}

MainLayout.propTypes = {
    showLogoutButton: PropTypes.bool.isRequired
};

export default MainLayout;