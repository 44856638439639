import React, { Component } from 'react';
import MainLayout from "../layouts/MainLayout";
import CategoryItem from "../items/CategoryItem";
import Fetcher from "../util/Fetcher";
import { withRouter } from "react-router-dom";
import RaceInformation from '../items/category/RaceInformation';
import UploadRaceList from "./UploadRaceList";

function Table(props) {
    return (
        <div>
            <h3>Състезателни групи</h3>
            <table className="table table-hover table-responsive-md mb-5 pb-5 card-shadow">
                <thead className="thead-dark">

                    <CategoryEditInput isLoggedIn={props.isLoggedIn}
                        isEditing={props.isEditing}
                        handleAddCategoryClick={props.handleAddCategoryClick}
                        handleNameInputOnChange={props.handleNameInputOnChange}
                        nameInput={props.nameInput} />

                    <tr>
                        <th scope="col">Състезателни категории:</th>
                        <th scope="col">Операции</th>
                    </tr>
                </thead>

                <TableBody handleDeleteItem={props.handleDeleteItem}
                    handleEdit={props.handleEdit}
                    isRace={props.isRace}
                    isLoggedIn={props.isLoggedIn}
                    categories={props.categories} />
            </table>

        </div>
    );
}

function TableBody(props) {
    return (
        <tbody>

            {props.categories.map((object, index) => {
                return <CategoryItem key={index} dataId={index} handleDelete={props.handleDeleteItem}
                    handleEdit={props.handleEdit}
                    infoObject={object}
                    isRace={props.isRace}
                    isLoggedIn={props.isLoggedIn} />; //sessionStorage.getItem('race') === 'true'
            })}

        </tbody>

    );
}

/**
 * @return {null}
 */
function CategoryEditInput(props) {
    if (!props.isLoggedIn) {
        return null;
    }
    return (
        <tr>
            <th scope="col-5">
                Добави нова група:
            </th>

            <th scope="col-7">
                <div className="row">
                    <div className="col-7">
                        <input type="text" className="form-control" value={props.nameInput}
                            placeholder={"Име на категорията"}
                            aria-label="" aria-describedby="button-addon1"
                            onChange={props.handleNameInputOnChange} />
                    </div>
                    <div className="col-4">
                        <button className="btn btn-primary"
                            onClick={props.handleAddCategoryClick}>{props.isEditing ? "Редактирай" : "Добави"}</button>
                    </div>
                </div>
            </th>
        </tr>
    );
}

class CategoryList extends Component {

    state = {
        isRace: false,
        raceObj: {},
        nameInput: "",
        isLoggedIn: false,
        editItemId: null,
        isEditing: false,
        categories: []
    };

    componentDidMount() {
        const id = this.props.match.params.id;
        sessionStorage.setItem('race_id', id);
        let isLogged = sessionStorage.getItem('isLoggedIn');
        this.setState({ isLoggedIn: isLogged === '1' });
        this.fetchAllCategories(id);
    }

    async fetchAllCategories(id) {
        let body = await Fetcher.fetchCategories(id);
        if (body === null) {
            alert("Проблем при зареждане на категориите");
        } else {
            this.setState({ categories: body.categories, raceObj: body });
        }
    }

    handleNameInputOnChange = e => {
        this.setState({ nameInput: e.target.value });
    };

    handleAddCategoryClick = async () => {
        if (this.state.isEditing) {
            this.updateCategoryInTheDb(this.state.editItemId, { name: this.state.nameInput });
        } else {
            await this.addCategoryToDb({ name: this.state.nameInput }, sessionStorage.getItem('race_id'));
        }
    };

    async deleteCategoryFromDb(id) {
        const status = await Fetcher.deleteCategory(id);
        if (status === 200) {
            this.fetchAllCategories(sessionStorage.getItem('race_id'));
        } else {
            alert("Проблем при изтриването на категорията! Код: " + status);
        }
    }


    async updateCategoryInTheDb(id, updatedCategory) {
        const status = await Fetcher.updateCategory(id, updatedCategory);
        if (status === 200) {
            this.fetchAllCategories(sessionStorage.getItem('race_id'));
        } else {
            alert("Проблем при обновяването на категорията! Код: " + status);
        }
        this.setState({ nameInput: "", editItemId: null, isEditing: false });
    }

    async addCategoryToDb(cat, id) {
        const status = await Fetcher.createCategory(cat, id);
        if (status === 200) {
            this.clearInput();
            this.fetchAllCategories(sessionStorage.getItem('race_id'));
        } else {
            alert("Проблем при добавянето на категорията! Код: " + status);
        }
    }

    clearInput = () =>
        this.setState({ nameInput: '' });

    handleEditItem = e => {
        let category = this.state.categories[e.target.dataset.id];
        this.setState(
            {
                nameInput: category.name,
                editItemId: category.id,
                isEditing: true
            }
        );
    };

    handleDeleteItem = e => {
        const category = this.state.categories[e.target.dataset.id];
        this.deleteCategoryFromDb(category.id);
    };

    render() {
        return (
            <div>
                <MainLayout
                    showLogoutButton={this.state.isLoggedIn}
                    backButtonLink={"/race-list"}
                >
                    <RaceInformation name={this.state.raceObj.name} laps={this.state.raceObj.laps}
                        points_for_normal_lap={this.state.raceObj.points_for_normal_lap}
                        point_lap={this.state.raceObj.point_lap}
                        points_for_place={this.state.raceObj.points_for_place}
                        lap_distance={this.state.raceObj.lap_distance}
                        router_props={this.props}
                        isLoggedIn={this.state.isLoggedIn} />

                    <UploadRaceList isLoggedIn={this.state.isLoggedIn} raceId={this.state.raceObj.id} />

                    <Table
                        isLoggedIn={this.state.isLoggedIn}
                        isEditing={this.state.isEditing}
                        isRace={this.state.isRace}
                        handleAddCategoryClick={this.handleAddCategoryClick}
                        handleNameInputOnChange={this.handleNameInputOnChange}
                        nameInput={this.state.nameInput}
                        handleDeleteItem={this.handleDeleteItem}
                        handleEdit={this.handleEditItem}
                        categories={this.state.categories} />

                </MainLayout>
            </div>
        );
    }

}

export default withRouter(CategoryList);