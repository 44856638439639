import React from 'react';

export default function Labels(props) {
    return (
        <tr>
            {!props.isLoggedIn && <th scope="col">Позиция</th>}
            <th scope="col">Номер</th>
            <th scope="col">Име и Фамилия</th>
            <th scope="col">UCI код</th>
            <th scope="col">Отбор</th>
            <th scope="col">Точки</th>
            <th scope="col">{props.isLoggedIn && "Операции"}</th>
        </tr>
    );
}


