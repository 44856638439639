import React from 'react';
import { Link } from 'react-router-dom';
/**
 * @return {null}
 */
function UpdateRace(props) {
    if (props.isLoggedIn) {
        return <Link to={`${props.router_props.match.url}/update_race`} className="btn btn-dark">Редактирай
            състезание</Link>;
    }
    return null;
}


export default function RaceInformation(props) {
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h1>Информация за състезание <strong>"{props.name}"</strong></h1>
                <UpdateRace router_props={props.router_props} isLoggedIn={props.isLoggedIn} />
            </div>

            <div className="list-group mb-4 card-shadow">
                {props.laps && (
                    <li className="list-group-item list-group-item-action"><strong>Обиколки:</strong> {props.laps}</li>
                )}
                <li className="list-group-item list-group-item-action">
                    <strong>Точки за обиколка: </strong> {props.points_for_normal_lap}
                </li>
                <li className="list-group-item list-group-item-action">
                    <strong>Спринтова обиколка всяка </strong>
                    {props.point_lap}
                    <strong> обиколка с точки </strong>
                    {props.points_for_place}
                </li>
                <li className="list-group-item list-group-item-action">
                    <strong>Дължина на обиколка:</strong> {props.lap_distance}
                </li>
            </div>
        </div>
    );
}
