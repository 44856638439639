import React from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

const BackButton = props =>
    <div>
        <Link to={props.link} className="btn btn-secondary"> {"< Назад"}</Link>
    </div>;

BackButton.propTypes = {
    link: PropTypes.string.isRequired
};
export default BackButton;