import React from 'react';
import { NavLink } from "react-router-dom";
import Constants from "../util/Constants";


export default () => {
    return (
        <div>

            <nav className="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav">
                <div className="container d-flex justify-content-between">
                    <a className="navbar-brand js-scroll-trigger text-white" href="/">Doltcini Cup</a>
                    <div className="">
                        <NavLink to="/login" className="btn btn-info">Вход</NavLink>
                    </div>
                </div>
            </nav>

            <header className="masthead">
                <div className="container h-100">
                    <div className="row h-100 align-items-center justify-content-center text-center">
                        <div className="col-lg-10 align-self-end">
                            <img src={Constants.LOGO_URL} alt="Doltcini Logo" />
                        </div>
                        <div className="col-lg-10 align-top">
                            <h1 className="text-uppercase text-white font-weight-bold">Професионален шампионат по
                                колоездене -
                            Купа Doltcini</h1>
                            <hr className="divider my-4" />
                        </div>
                        <div className="col-lg-8 align-self-baseline">
                            <p className="text-white-75 font-weight-light mb-5 text-white">Следете последните
                            резултати от състезанията.</p>
                            <NavLink to="/race-list" className="btn btn-info btn-xl js-scroll-trigger">Към резултатите
                        </NavLink>
                        </div>
                    </div>
                </div>
            </header>

        </div>
    );
}