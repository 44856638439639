import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

export default function CategoryItem(props) {
    return (
        <>
            <tr>
                <th scope="row">
                    <NavLink
                        to={props.isRace ? "/category/" + props.infoObject.id : "/rider-list/" + props.infoObject.id}>
                        {props.infoObject.name}
                    </NavLink>
                </th>
                <td>
                    <NavLink
                        to={props.isRace ? "/category/" + props.infoObject.id : "/rider-list/" + props.infoObject.id}
                        className="btn btn-primary m-1"
                        data-id={props.dataId}>Отвори
            </NavLink>
                    {props.isLoggedIn ?
                        props.isRace ?
                            "" :
                            <button className="btn btn-dark m-1" data-id={props.dataId}
                                onClick={props.handleEdit.bind(this)}>Редакция
                    </button>
                        : ""}

                    {props.isLoggedIn ?
                        <button className="btn btn-danger m-1" data-id={props.dataId}
                            onClick={props.handleDelete}>Премахни</button>
                        :
                        ""
                    }

                </td>
            </tr>
        </>
    );
}

CategoryItem.propTypes = {
    infoObject: PropTypes.object.isRequired,
    dataId: PropTypes.number.isRequired,
    handleEdit: PropTypes.func,
    handleDelete: PropTypes.func.isRequired,
    isRace: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired
};

