import React from 'react';

export default function AddNewRider(props) {
    if (props.isLoggedIn) {
        return (
            <>
                <tr>
                    <th colSpan="3">Добави нов състезател:</th>
                    <th colSpan="2">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={"Номер"}
                            value={props.numberInput}
                            onChange={(e) => {
                                props.dispatch({
                                    type: 'field',
                                    field: 'numberInput',
                                    value: e.currentTarget.value
                                })
                            }}
                        />
                    </th>
                    <th colSpan="1">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={"Имена"}
                            value={props.nameInput}
                            onChange={(e) => {
                                props.dispatch({
                                    type: 'field',
                                    field: 'nameInput',
                                    value: e.currentTarget.value
                                })
                            }}
                        />
                    </th>
                </tr>
                <tr>
                    <th colSpan="2">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={"UCI"}
                            value={props.uciInput}
                            onChange={(e) => {
                                props.dispatch({
                                    type: 'field',
                                    field: 'uciInput',
                                    value: e.currentTarget.value
                                })
                            }}
                        />
                    </th>
                    <th scope="col">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={"Отбор"}
                            value={props.teamInput}
                            onChange={(e) => {
                                props.dispatch({
                                    type: 'field',
                                    field: 'teamInput',
                                    value: e.currentTarget.value
                                })
                            }}
                        />
                    </th>
                    <th colSpan="2">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={"Точки"}
                            value={props.pointsInput}
                            onChange={(e) => {
                                props.dispatch({
                                    type: 'field',
                                    field: 'pointsInput',
                                    value: e.currentTarget.value
                                })
                            }}
                        />
                    </th>
                    <th scope="col">
                        <button className="btn btn-primary"
                            onClick={props.onSubmit}>{props.isEditing ? "Редактирай" : "Добави"}</button>
                    </th>
                </tr>
            </>
        )
    }

    return null;
}