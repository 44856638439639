import React, { useState, useEffect, useReducer } from 'react';
import TableItem from "../items/TableItem";
import Fetcher from "../util/Fetcher";
import styled from 'styled-components';
import MainLayout from "../layouts/MainLayout";
import Labels from '../items/Labels';
import AddNewRider from '../items/AddNewRider';
import Select from 'react-select';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

const RiderList = styled.tbody`
    padding: 8px;
    transition: background-color 0.2s ease;
    background-color: ${props => (props.isDraggingOver ? 'skyblue' : 'white')};
    min-height: 100px;
`;

const TableContainer = styled.div`
    margin-top: 10px;
    margin-bottom: 60px;
`;

const SelectWrapper = styled.div`
    width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const selectOptions = [
    { value: 'racing_number', label: 'Номер' },
    { value: 'ranking', label: 'Класиране' },
]

function RiderFetchSwitch(props) {
    if (props.isLoggedIn) {
        return (
            <SelectWrapper>
                <div>
                    <p className="mt-3">Сортирай по:</p>
                </div>
                <div className='col'>
                    <Select
                        defaultValue={selectOptions[0]}
                        value={props.selectedValue}
                        options={selectOptions}
                        onChange={(selectedOption) => {
                            props.dispatch({ type: 'select', value: selectedOption })
                        }}
                    />
                </div>
            </SelectWrapper>
        );
    }
    return null;
}

function riderListReducer(state, action) {
    switch (action.type) {
        case 'select':
            return {
                ...state,
                selectedValue: action.value,
                sortByRacingNumber: action.value === selectOptions[0]
            }
        case 'update_on_drag':
            return {
                ...state,
                objects: action.objects
            }
        case 'set_logged_in':
            return {
                ...state,
                isLoading: action.isLoggedIn
            }
        case 'loading':
            return {
                ...state,
                isLoading: true
            }
        case 'success':
            return {
                ...state,
                isLoading: false,
                categoryName: action.categoryName,
                objects: action.objects
            }
        case 'field':
            return {
                ...state,
                [action.field]: action.value
            };
        case 'edit':
            return {
                ...state,
                currentEditInArrayId: action.event,
                currentEditId: action.object.id,
                numberInput: action.object.racing_number,
                nameInput: action.object.name,
                uciInput: action.object.uci_code,
                teamInput: action.object.team_name,
                pointsInput: action.object.ranking_point != null ? action.object.ranking_point.points : 0,
                isEditing: true
            }
        case 'clear_fields':
            return {
                ...state,
                isEditing: false,
                numberInput: '',
                nameInput: '',
                uciInput: '',
                teamInput: '',
                pointsInput: '',
            }
        case 'error':
            return {
                ...state,
                error: action.message
            }
        default:
            break;
    }

}

const initialState = {
    isEditing: false,
    isLoading: false,
    currentEditId: null,
    currentEditInArrayId: null,
    categoryName: '',
    numberInput: '',
    nameInput: '',
    uciInput: '',
    teamInput: '',
    pointsInput: '',
    objects: [],
    selectedValue: selectOptions[0],
    sortByRacingNumber: true
}

export default function ItemList(props) {

    const [state, dispatch] = useReducer(riderListReducer, initialState);

    const { numberInput, nameInput, uciInput, teamInput, pointsInput, categoryName,
        objects, currentEditInArrayId, isEditing, currentEditId,
        isLoading, error, selectedValue, sortByRacingNumber } = state;

    const [isLoggedIn, setLoggedIn] = useState(false);

    let interval;

    useEffect(() => {
        setLoggedIn(sessionStorage.getItem('isLoggedIn') === '1');

        const id = props.match.params.id;
        sessionStorage.setItem('category_id', id);
        //TODO: don't think this is supposed to be like this
        sessionStorage.setItem('ranking_id', 1);

        const temp = sessionStorage.getItem('isLoggedIn') === '1';

        if (temp) {
            fetchRiders();
        } else {
            interval = setInterval(() => {
                fetchResults();
            }, 5000);
            fetchResults();
        }
    }, [sortByRacingNumber]);

    const onSubmit = () => {
        let ranking_point = null;
        if (typeof objects[currentEditInArrayId] !== 'undefined') {
            ranking_point = objects[currentEditInArrayId].ranking_point;
            if (ranking_point !== null) {
                ranking_point.points = pointsInput;
            } else {
                ranking_point = {
                    points: pointsInput
                };
            }
        } else {
            ranking_point = {
                points: pointsInput
            };
        }

        let newRider = {
            name: nameInput,
            racing_number: numberInput,
            uci_code: uciInput,
            team_name: teamInput,
            ranking_point: ranking_point
        };

        if (isEditing) {
            updateRider(currentEditId, newRider);
        } else {
            createRider(newRider);
        }
    };

    const editRider = e => {
        dispatch({ type: 'edit', event: e.target.dataset.id, object: objects[e.target.dataset.id] });
    };

    /**
     * Add new rider to the API
     * @param {*} rider 
     */
    const createRider = async (rider) => {
        dispatch({ type: 'loading' });
        try {
            await Fetcher.addRider(sessionStorage.getItem('category_id'), rider);
            dispatch({ type: 'clear_fields' });
            fetchRiders();

        } catch (e) {
            dispatch({ type: 'error', message: 'Грешка при създаването на състезателя!' })
        }
    }

    /**
     * Delete item from db and refreshes ui
     * @param e
     */
    const deleteRider = async (e) => {
        let rider = objects[e.target.dataset.id];
        dispatch({ type: 'loading' });
        try {
            await Fetcher.deleteRider(rider.id);
            fetchRiders();
        } catch (e) {
            dispatch({ type: 'error', message: 'Грешка при изтриването на състезателя!' })
        }
    };

    const fetchRiders = () => {
        dispatch({ type: 'loading' });
        Fetcher.fetchRidersForCategory(sessionStorage.getItem('category_id'), sortByRacingNumber)
            .then((value) => {
                dispatch({ type: 'success', categoryName: value.name, objects: value.riders });
            })
            .catch(() => {
                dispatch({ type: 'error', message: 'Проблем при зареждането на състезателния лист' });
            });
    }

    const updateRider = async (id, updatedRider) => {
        dispatch({ type: 'loading' });
        try {
            await Fetcher.updateRider(id, updatedRider);
            dispatch({ type: 'clear_fields' });
            fetchRiders();
        } catch (e) {
            dispatch({ type: 'error', message: 'Проблем при обновяването на състезателя!' })
        }
    }

    const fetchResults = () => {
        Fetcher.fetchRidersForCategory(sessionStorage.getItem('category_id'), false)
            .then((value) => {
                dispatch({ type: 'success', categoryName: value.name, objects: value.riders });
            })
            .catch((_) => {
                dispatch({ type: 'error', message: 'Проблем при зареждането на резултатите' })
            });
    }

    const updateRanking = async (newRanking) => {
        const rankingObject = {
            "in_progress": "true",
            "speed": 39.4,
            "time": 150,
            "current_lap": 15,
            "ranking": newRanking
        };
        dispatch({ type: 'loading' });
        try {
            await Fetcher.updateRankingForCategory(sessionStorage.getItem('category_id'), rankingObject);
            fetchRiders();
        } catch (e) {
            dispatch({ type: 'error', message: 'Проблем при обновяването на класирането! Опитайте отново!' });
        }
    }

    const onDragEnd = (result) => {
        const { destination, source } = result;
        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        // rider that has been dragged to a new place
        const riderMoved = objects[source.index];

        let newRiderArray = Array.from(objects);

        // put the rider in the new space in the array
        newRiderArray.splice(source.index, 1);
        newRiderArray.splice(destination.index, 0, riderMoved);

        newRiderArray = newRiderArray.map((rider, index) => {
            return {
                ...rider,
                ranking_point: {
                    ...rider.ranking_point,
                    rank: index + 1,
                    riderId: rider.id
                }
            }
        });

        // extract the ranking_point from each rider
        const rankingArray = newRiderArray.map((rider) => {
            return rider.ranking_point;
        });

        updateRanking(rankingArray);
        return;
    }

    return (
        <div>
            <MainLayout
                showLogoutButton={isLoggedIn}
                backButtonLink={"/category/" + sessionStorage.getItem('race_id')}
            >

                <h1 className="mb-4">{isLoggedIn ? "Състезателен лист за категория " : "Резултати за категория "}
                    <strong>"{categoryName}"</strong></h1>
                <RiderFetchSwitch
                    value={selectedValue}
                    dispatch={dispatch}
                    isLoggedIn={isLoggedIn}
                />
                <TableContainer>
                    <table className="table table-hover table-responsive-md mb-5 pb-5 card-shadow">
                        <thead className="thead-dark">
                            <AddNewRider
                                isEditing={isEditing}
                                dispatch={dispatch}
                                onSubmit={onSubmit}
                                isLoggedIn={isLoggedIn}
                                numberInput={numberInput}
                                nameInput={nameInput}
                                uciInput={uciInput}
                                teamInput={teamInput}
                                pointsInput={pointsInput}
                            />
                            <Labels isLoggedIn={isLoggedIn} />
                            {isLoading && <tr><th colSpan="6">Зареждане на състезателите...</th></tr>}
                            {error && <tr><th colSpan="6">{error}</th></tr>}
                        </thead>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId={1}>

                                {(provided, snapshot) => (
                                    <RiderList
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        isDraggingOver={snapshot.isDraggingOver}
                                    >
                                        {objects.map((object, index) => {
                                            return <TableItem
                                                draggableId={object.id}
                                                key={object.id}
                                                infoObject={object}
                                                handleEdit={editRider}
                                                handleDelete={deleteRider}
                                                dataId={index}
                                                isLoggedIn={isLoggedIn}
                                                index={index}
                                                isDragDisabled={!isLoggedIn || sortByRacingNumber}
                                            />
                                        })}
                                        {provided.placeholder}
                                    </RiderList>
                                )}

                            </Droppable>
                        </DragDropContext>
                    </table>
                </TableContainer>
            </MainLayout>
        </div>
    )

}