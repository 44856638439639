import React, { useReducer } from 'react';
import ReactLoading from 'react-loading';
import Fetcher from '../util/Fetcher';


const initialData = {
    selectedFile: null,
    isLoading: false,
    isError: null,
}

const reducer = (state, action) => {
    switch (action.type) {
        case "upload_start": {
            return {
                ...state,
                isLoading: true,
                selectedFile: action.file,
            }
        }
        case "upload_success": {
            window.location.reload(false);
            return {
                ...state,
                isLoading: false,
                isError: null,
            }
        }
        case "upload_fail": {
            return {
                ...state,
                isLoading: false,
                isError: <p><strong>Проблем с качването на файла!</strong> Проверете дали изискванията за файла са изпълнени!</p>,
            }
        }
    }
}


const UploadRaceList = ({ raceId, isLoggedIn }) => {
    const [state, dispatch] = useReducer(reducer, initialData);

    const { selectedFile, isLoading, isError } = state;

    const onClickInput = (e) => {
        alert("Внимание!\nКачването на нов стартов лист ще изтрие \nвсички предишни качени състезателни категории, състезатели и резултати.");
    }


    const onFileUploadHandler = (e) => {
        const file = e.target.files[0];
        dispatch({ type: "upload_start", file: file });

        Fetcher.uploadRaceList(file, raceId)
            .then(res => {
                if (res.status === 200) {
                    dispatch({ type: "upload_success" });
                } else {
                    dispatch({ type: "upload_fail" });
                }
            })
            .catch(err => {
                dispatch({ type: "upload_fail" });
                console.log(err);
            });
    }

    if (!isLoggedIn) {
        return null;
    }
    return (
        <>
            <div className='alert alert-warning'>
                <h4>Качване на стартов лист</h4>
                <p className='text-danger'>
                    <strong>ВНИМАНИЕ!</strong> Качването на нов стартов лист ще изтрие всички предишни качени състезателни категории, състезатели и резултати.
                    Състезателния лист трябва да е във формат <strong>.csv</strong> и да има следва формата на прикачения файл.
                    <br/>
                    <a href="http://api.cup.doltcini.com/files/start-list.csv" target="_blank" rel="noopener noreferrer" download={true}>Свали примерен състезателен лист</a>
                </p>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className="input-group mb-3">
                            <div className="custom-file">
                                <input type="file" className="custom-file-input" accept=".csv" id="inputRaceFileUpload" aria-describedby="inputFileUpload" onChange={onFileUploadHandler} onClick={onClickInput} />
                                <label className="custom-file-label" htmlFor="inputRaceFileUpload">{selectedFile ? selectedFile.name : "Избери файл"}</label>
                            </div>
                        </div>
                    </div>
                </div>
                {isLoading && (
                    <div className="d-inline">
                        <ReactLoading type="bars" color="cyan" height={15} width={45} />
                        <p className="text-info ml-5">Избраният файл се качва...</p>
                    </div>
                )}
                {isError && (
                    <div className="d-inline">
                        <p className="text-danger">{isError}</p>
                    </div>
                )}
            </div>
        </>
    )

}


export default UploadRaceList;