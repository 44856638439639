import React, { Component } from "react";
import MainLayout from "../layouts/MainLayout";
import Fetcher from "../util/Fetcher";
import { Redirect } from "react-router-dom";

function Title(props) {
    return <h2 className="mb-4">{props.update ? 'Редактирай състезанието' : 'Добави състезание'}</h2>;
}

function SubmitButton(props) {
    return <button type="submit" className="btn btn-primary mb-5">{props.update ? 'Редактирай' : 'Добави'}</button>;
}

function RedirectBack(props) {
    if (!props.update) {
        return <Redirect to={"/race-list"} />
    }
    return <Redirect to={"/category/" + props.race_id} />
}

class AddRace extends Component {

    state = {
        isRedirect: false,
        name: "",
        laps: "",
        points_for_normal_lap: "",
        point_lap: "",
        points_for_place: "",
        lap_distance: "",
        update: false,
        race_id: 0
    };

    componentWillMount() {
        let splitUrl = this.props.match.url.split('/');
        let where = splitUrl[1];

        this.setState({ update: where !== "add-race" });
        this.setState({ race_id: where !== "add-race" ? splitUrl[2] : 0 });
    }

    componentDidMount() {
        if (this.state.update) {
            this.fetchRaceToUpdate(this.state.race_id);
        }
    }

    async fetchRaceToUpdate(id) {
        let body = await Fetcher.fetchCategories(id);
        if (body === null) {
            alert("Проблем при зареждането на състезанието. Моля опитайте отново!");
        } else {
            console.log(body);
            this.setState({
                lap_distance: body.lap_distance,
                laps: body.laps,
                name: body.name,
                point_lap: body.point_lap,
                points_for_normal_lap: body.points_for_normal_lap,
                points_for_place: body.points_for_place,
            });

        }

    }

    handleTextChange = e => {
        console.log("Text change e.target.id: " + e.target.id);
        switch (e.target.id) {
            case 'name':
                this.setState({ name: e.target.value });
                break;
            case 'laps':
                this.setState({ laps: e.target.value });
                break;
            case 'points_for_normal_lap':
                this.setState({ points_for_normal_lap: e.target.value });
                break;
            case 'point_lap':
                this.setState({ point_lap: e.target.value });
                break;
            case 'points_for_place':
                this.setState({ points_for_place: e.target.value });
                break;
            case 'lap_distance':
                this.setState({ lap_distance: e.target.value });
                break;
            default:
                console.log('not recognised');
        }
    };

    handleSubmit = e => {
        e.preventDefault();

        let race_obj = {
            name: this.state.name,
            // laps: this.state.laps,
            laps: 1,
            points_for_normal_lap: this.state.points_for_normal_lap,
            point_lap: this.state.point_lap,
            points_for_place: this.state.points_for_place,
            lap_distance: this.state.lap_distance,
        };

        this.createOrUpdateRaceInDb(race_obj);
    };

    async createOrUpdateRaceInDb(race) {
        if (this.state.update) {
            const status = await Fetcher.updateRace(this.state.race_id, race);
            if (status === 200) {
                this.setState({ isRedirect: true });
            } else {
                alert("Грешка при обновяването на състезанието");
            }
        } else {
            const status = await Fetcher.createRace(race);
            if (status === 200) {
                this.setState({ isRedirect: true });
            } else {
                alert("Грешка при обновяването на състезанието");
            }
        }
    }

    render() {
        return (
            <div>
                <MainLayout showLogoutButton={true} backButtonLink={"/race-list"}>

                    <Title update={this.state.update} />

                    <form onSubmit={this.handleSubmit} className="mb-5">
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Име на състезанието</label>
                            <input type="text" className="form-control" id="name" aria-describedby="emailHelp"
                                placeholder="Витоша 100" value={this.state.name} onChange={this.handleTextChange} />
                        </div>

                        {/*<div className="form-group">*/}
                        {/*    <label htmlFor="exampleInputEmail1">Обиколки</label>*/}
                        {/*    <input type="text" className="form-control" id="laps" aria-describedby="emailHelp"*/}
                        {/*           placeholder="10" value={this.state.laps} onChange={this.handleTextChange}/>*/}
                        {/*</div>*/}

                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Точки за нормална обиколка</label>
                            <input type="text" className="form-control" id="points_for_normal_lap"
                                aria-describedby="emailHelp"
                                placeholder="1" value={this.state.points_for_normal_lap} onChange={this.handleTextChange} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Точки за обиколка (point_lap)</label>
                            <input type="text" className="form-control" id="point_lap"
                                aria-describedby="emailHelp"
                                placeholder="1" value={this.state.point_lap} onChange={this.handleTextChange} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Точки за 1, 2, 3, 4 място</label>
                            <input type="text" className="form-control" id="points_for_place"
                                aria-describedby="emailHelp"
                                placeholder="5|3|2|1" value={this.state.points_for_place} onChange={this.handleTextChange} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Дължина на обиколката в метри</label>
                            <input type="text" className="form-control" id="lap_distance"
                                aria-describedby="emailHelp"
                                placeholder="950" value={this.state.lap_distance} onChange={this.handleTextChange} />
                        </div>


                        <SubmitButton update={this.state.update} />
                    </form>
                </MainLayout>
                {this.state.isRedirect ? <RedirectBack update={this.state.update} race_id={this.state.race_id} /> : ""}
            </div>);
    }

}

export default AddRace;