import Constants from "./Constants";

class Fetcher {

    /**
     * Define the headers for the API
     */
    static getHeaders() {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", Constants.API_JWT);
        return headers;
    }

    static getOptionsFor(method) {
        return {
            mode: 'cors',
            method: method,
            headers: this.getHeaders()
        };
    }

    /**
     * Add rider to a specific category
     * @param catId the id of the category
     * @param rider json object
     * @returns {Promise<void>}
     */
    static async addRider(catId, rider) {
        const options = {
            method: 'POST',
            headers: this.getHeaders(),
            body: JSON.stringify(rider)
        };

        const fetchUrl = process.env.REACT_APP_SERVER_URL + "/category/" + catId + "/rider";
        const request = new Request(fetchUrl, options);
        const response = await fetch(request);
        const status = await response.status;

        return new Promise((resolve, reject) => {
            if (status === 200) {
                resolve();
            } else {
                reject();
            }
        });
    }

    /**
     * Delete rider by id
     * @param riderId
     * @returns {Promise<void>}
     */
    static async deleteRider(riderId) {
        const fetchUrl = process.env.REACT_APP_SERVER_URL + "/rider/" + riderId;
        const request = new Request(fetchUrl, this.getOptionsFor('DELETE'));
        const response = await fetch(request);
        return await response.status;
    }

    /**
     * Get all riders to display
     * @returns {Promise<*>}
     */
    static async fetchRidersForCategory(id, sortByRacingNumber) {
        const fetchUrl = process.env.REACT_APP_SERVER_URL + "/category/" + id + (sortByRacingNumber ? "?order=racing_number" : "");
        const request = new Request(fetchUrl, this.getOptionsFor('GET'));
        const response = await fetch(request);
        const body = await response.json();

        return new Promise((resolve, reject) => {
            if (response.status === 200) {
                resolve(body);
            } else {
                reject();
            }
        })
    };

    /**
     * Updates rider with id
     * @param id
     * @param updatedRider
     */
    static async updateRider(id, updatedRider) {
        const options = {
            method: 'PUT',
            headers: this.getHeaders(),
            body: JSON.stringify(updatedRider)
        };

        const fetchUrl = process.env.REACT_APP_SERVER_URL + "/rider/" + id;

        const request = new Request(fetchUrl, options);
        const response = await fetch(request);
        const status = await response.status;

        return new Promise((resolve, reject) => {
            if (status === 200) {
                resolve();
            } else {
                reject();
            }
        });
    }

    /**
     * Get all categories
     * @returns {Promise<*>}
     */
    static async fetchCategories(id) {
        const fetchUrl = process.env.REACT_APP_SERVER_URL + "/race/" + id;

        const request = new Request(fetchUrl, this.getOptionsFor('GET'));
        const response = await fetch(request);
        const body = await response.json();


        console.log(`status: ${response.status}`);
        if (response.status === 200) return body;
        else return null;
    }

    /**
     * Update category by id
     * @param id
     * @param category
     * @returns {Promise<void>}
     */
    static async updateCategory(id, category) {
        const options = {
            method: 'PUT',
            headers: this.getHeaders(),
            body: JSON.stringify(category)
        };

        const fetchUrl = process.env.REACT_APP_SERVER_URL + "/category/" + id;

        const request = new Request(fetchUrl, options);
        const response = await fetch(request);
        return await response.status;
    }

    /**
     * Create new category
     * @param cat
     * @returns {Promise<void>}
     */
    static async createCategory(cat, id) {
        const options = {
            method: 'POST',
            headers: this.getHeaders(),
            body: JSON.stringify(cat)
        };

        const fetchUrl = process.env.REACT_APP_SERVER_URL + "/race/" + id + "/category";

        const request = new Request(fetchUrl, options);
        const response = await fetch(request);
        return await response.status;
    }

    /**
     * Delete category by id
     * @param id
     * @returns {Promise<void>}
     */
    static async deleteCategory(id) {
        const fetchUrl = process.env.REACT_APP_SERVER_URL + "/category/" + id;
        const request = new Request(fetchUrl, this.getOptionsFor('DELETE'));
        const response = await fetch(request);
        return await response.status;
    }

    /**
     * Get all races
     * @returns {Promise<*>}
     */
    static async fetchRaces() {
        const fetchUrl = process.env.REACT_APP_SERVER_URL + "/race";
        const request = new Request(fetchUrl, this.getOptionsFor('GET'));
        const response = await fetch(request);
        const body = await response.json();

        return new Promise((resolve, reject) => {
            if (response.status === 200) {
                resolve(body);
            } else {
                reject();
            }
        });
    }

    /**
     * Create race
     * @param race
     * @returns {Promise<void>}
     */
    static async createRace(race) {
        const options = {
            method: 'POST',
            headers: this.getHeaders(),
            body: JSON.stringify(race)
        };

        const fetchUrl = process.env.REACT_APP_SERVER_URL + "/race";

        const request = new Request(fetchUrl, options);
        const response = await fetch(request);
        return await response.status;
    }

    /**
     * Update race
     * @param id
     * @param race
     * @returns {Promise<void>}
     */
    static async updateRace(id, race) {
        const fetchURL = process.env.REACT_APP_SERVER_URL + "/race/" + id;
        const options = {
            method: 'PUT',
            headers: this.getHeaders(),
            body: JSON.stringify(race)
        };
        const request = new Request(fetchURL, options);
        const response = await fetch(request);
        return await response.status;
    }

    /**
     * Delete race with id
     * @param id
     * @returns {Promise<void>}
     */
    static async deleteRace(id) {
        const fetchUrl = process.env.REACT_APP_SERVER_URL + "/race/" + id;
        const request = new Request(fetchUrl, this.getOptionsFor('DELETE'));
        const response = await fetch(request);
        const status = await response.status;

        return new Promise((resolve, reject) => {
            if (status === 200) {
                resolve();
            } else {
                reject();
            }
        });
    }

    static async authUser(user) {
        const options = {
            method: 'POST',
            headers: this.getHeaders(),
            body: JSON.stringify(user)
        };

        const fetchURL = process.env.REACT_APP_SERVER_URL + "/auth/generatejwt";
        const request = new Request(fetchURL, options);
        const response = await fetch(request);
        const status = await response.status;
        console.log(user);

        console.log(`status: ${status}`);
        return new Promise((resolve, reject) => {
            if (status === 200) {
                resolve();
            } else {
                reject();
            }
        });
    }

    static async updateRankingForCategory(category_id, new_ranking) {
        const fetchURL = process.env.REACT_APP_SERVER_URL + '/category/' + category_id + '/ranking';
        const options = {
            method: 'POST',
            headers: this.getHeaders(),
            body: JSON.stringify(new_ranking)
        };

        const request = new Request(fetchURL, options);
        const response = await fetch(request);
        const status = await response.status;

        
        return new Promise((resolve, reject) => {
            if(status === 200) {
                resolve();
            } else {
                reject();
            }
        });
    }

    static uploadRaceList(file, raceId) {
        const data = new FormData();
        data.append('file', file);

        const headers = new Headers();
        headers.append("Authorization", Constants.API_JWT);

        const fetchURL = process.env.REACT_APP_SERVER_URL + '/race/' + raceId +'/upload';
        const options = {
            method: 'POST',
            headers: headers,
            body: data
        };

        const request = new Request(fetchURL, options);
        const response = fetch(request);
        
        return response;
    }
}

export default Fetcher;