import React, { useReducer, useEffect } from 'react';
import FormLayout from '../layouts/FormLayout';
import MainLayout from '../layouts/MainLayout';
import { Redirect } from 'react-router-dom';
import Fetcher from '../util/Fetcher';


function loginReducer(state, action) {
    switch (action.type) {
        case 'field':
            return {
                ...state,
                [action.field]: action.value
            }
        case 'login':
            return {
                ...state,
                isLoading: true,
                error: '',
            };
        case 'success':
            sessionStorage.setItem("isLoggedIn", '1');
            return {
                ...state,
                isLoading: false,
                isLoggedIn: true,
                error: '',
                username: '',
                password: ''
            };
        case 'error':
            return {
                ...state,
                isLoading: false,
                error: 'Грешни име или парола!'
            }
        default:
            break;
    }
}

const initialState = {
    email: '',
    password: '',
    isLoading: false,
    isLoggedIn: false,
    error: ''
}

export default function Login() {

    const [state, dispatch] = useReducer(loginReducer, initialState);
    const { isLoading, isLoggedIn, username, password, error } = state;

    useEffect(() => {
        if (sessionStorage.getItem('isLoggedIn') === '1') {
            dispatch({type: 'success'});
        }
    });

    const onSubmit = async e => {
        e.preventDefault();

        const user = {
            username: username,
            password: password
        };

        dispatch({ type: 'login' });

        try {
            await Fetcher.authUser(user);
            dispatch({ type: 'success' });
        } catch (e) {
            dispatch({ type: 'error' });
        }
    };

    return (
        <div>
            <MainLayout showLogoutButton={false}>
                <FormLayout title="Вход за администратори">
                    {error && <p className='login-error'>{error}</p>}
                    <form onSubmit={onSubmit}>

                        <div className="form-group">
                            <label htmlFor="emailInput">Потребителкско име</label>
                            <input
                                type="text"
                                className="form-control"
                                id="emailInput"
                                aria-describedby="emailHelp"
                                placeholder="username"
                                value={username}
                                onChange={e =>
                                    dispatch({
                                        type: 'field',
                                        field: 'username',
                                        value: e.currentTarget.value
                                    })}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Парола</label>
                            <input
                                type="password"
                                className="form-control"
                                id="exampleInputPassword1"
                                placeholder="*********"
                                value={password}
                                onChange={e =>
                                    dispatch({
                                        type: 'field',
                                        field: 'password',
                                        value: e.currentTarget.value
                                    })}
                            />
                        </div>

                        <div className="d-flex justify-content-between">
                            <div>
                                <button type="submit" className="btn btn-primary">
                                    {isLoading ? "Влизане в системата ..." : "Вход"}
                                </button>
                            </div>
                        </div>

                    </form>

                    {isLoggedIn ? <Redirect to={"/race-list"} /> : ""}

                </FormLayout>
            </MainLayout>
        </div>
    );
}