import React from 'react';

export var Footer = () =>
    <div>
        <footer className="footer fixed-bottom bg-dark mt-2">

            <div className="footer-copyright text-center text-white py-3">
                © 2018 Copyright: Doltcini Cup
            </div>
        </footer>
    </div>
