import React, { useState, useEffect, useReducer } from 'react';
import MainLayout from "../layouts/MainLayout";
import CategoryItem from "../items/CategoryItem";
import Fetcher from "../util/Fetcher";
import { NavLink } from "react-router-dom";
import BackButton from "../items/BackButton";


function Header(props) {
    if (props.isLogged) {
        return (
            <div className="d-flex justify-content-between">
                <h1 className="mb-4">Всички състезания за {new Date().getFullYear()} година</h1>
                <div>
                    <NavLink className="btn btn-primary" to={"/add-race"}>Добави ново състезание</NavLink>
                </div>
            </div>
        )
    } else {
        return (<h1 className="mb-4">Всички състезания за {new Date().getFullYear()} година</h1>);
    }
}

function fetchRaceReducer(state, action) {
    switch (action.type) {
        case 'fetch':
            return {
                ...state,
                isLoading: true
            }
        case 'success':
            return {
                ...state,
                isLoading: false,
                races: action.races
            }
        case 'delete_success':
            return {
                ...state,
                isLoading: false,
            }
        case 'error':
            return {
                ...state,
                isLoading: false,
                error: action.message
            }
        default:
            break;
    }
}

const initialState = {
    isLoading: false,
    error: '',
    races: []
}

export default function RaceList() {

    const [state, dispatch] = useReducer(fetchRaceReducer, initialState);
    const [refresh, setRefresh] = useState(false);
    const [isLogged, setIsLoggedIn] = useState(false);

    const { isLoading, error, races } = state;

    useEffect(() => {
        setIsLoggedIn(sessionStorage.getItem('isLoggedIn') === '1');

        dispatch({ type: 'fetch' });
        Fetcher.fetchRaces()
            .then((value) => {
                dispatch({ type: 'success', races: value });
            })
            .catch(e => {
                dispatch({ type: 'error', message: 'Проблем при зареждането на състезанията.' });
            });
    }, [refresh]);

    const onDeleteItem = async e => {
        let raceId = e.target.dataset.id;
        dispatch({ type: 'fetch' });
        try {
            await Fetcher.deleteRace(raceId);
            dispatch({ type: 'delete_success' });
            setRefresh(!refresh);
        } catch (e) {
            dispatch({ type: 'error', message: 'Проблем при изтриването на състезанието.' });
        }
    };

    return (
        <MainLayout
            showLogoutButton={isLogged}
            backButtonLink={"/"}
        >

            <Header isLogged={isLogged} />

            <table className="table table-hover table-responsive-md mb-5 pb-5 card-shadow">
                <thead className="thead-dark">
                    <tr>
                        <th scope="col">Име на състезанието</th>
                        <th scope="col">Операции</th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading &&
                        <tr>
                            <td>Зареждане на състезанията...</td>
                            <td>&nbsp;</td>
                        </tr>
                    }
                    {error &&
                        <tr>
                            <td><p className="login-error">{error}</p></td>
                            <td>&nbsp;</td>
                        </tr>
                    }
                    {
                        races.map((object, index) => {
                            return <CategoryItem
                                key={index}
                                dataId={object.id}
                                handleDelete={e => onDeleteItem(e)}
                                infoObject={object}
                                isRace={true}
                                isLoggedIn={isLogged} />; //sessionStorage.getItem('race') === 'true'
                        })

                    }
                </tbody>
            </table>
        </MainLayout>
    );
}