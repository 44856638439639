import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';

const Container = styled.tr`
    border: 1px solid lightgrey;
    border-radius: 2px;
    background-color: ${props => (props.isDragging ? 'lightgreen' : 'white')};
    margin-bottom: 8px;
`

const getRankingPoint = (rankingPoint, isLoggedIn) => {
    if (rankingPoint !== null && typeof rankingPoint !== 'undefined') {
        switch (rankingPoint.points) {
            case -1:
                return "DNF";
            case -2:
                return "DNS";
            default:
                return rankingPoint.points;
        }
    } else {
        return "Oчаква се";
    }
}


const TableItem = props => {

    return (
        <Draggable
            draggableId={props.infoObject.id}
            index={props.index}
            isDragDisabled={props.isDragDisabled}
        >
            {(provided, snapshot) => (
                <Container
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    isDragging={snapshot.isDragging}
                >
                    {!props.isLoggedIn ? <th scope="row">{props.index + 1}</th> : ""}
                    <td>{props.infoObject.racing_number}</td>
                    <td>{props.infoObject.name}</td>
                    <td>{props.infoObject.uci_code}</td>
                    <td>{props.infoObject.team_name}</td>
                    <td>
                        {/*TODO: undefined problem*/}
                        {/* {(props.infoObject.ranking_point !== null && typeof props.infoObject.ranking_point !== 'undefined') ? props.infoObject.ranking_point.points : "Очаква се"} */}
                        {getRankingPoint(props.infoObject.ranking_point, props.isLoggedIn)}
                    </td>

                    {!props.isLoggedIn ?
                        <td>&nbsp;</td>
                        :
                        <td>
                            <button className="btn btn-dark m-1" data-id={props.dataId}
                                onClick={props.handleEdit.bind(this)}>Редакция
                            </button>
                            <button className="btn btn-danger m-1" data-id={props.dataId}
                                onClick={props.handleDelete.bind(this)}>Премахни
                            </button>
                        </td>
                    }
                    {provided.placeholder}
                </Container>
            )}
        </Draggable>
    );
}

TableItem.propTypes = {
    infoObject: PropTypes.object.isRequired,
    handleEdit: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    dataId: PropTypes.number.isRequired,
    isLoggedIn: PropTypes.bool.isRequired
};

export default TableItem;